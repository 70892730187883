import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import dummyFeatureLogoPath from '../../../assets/dummy-feature-logo.png';
import './why-section.scss';

export default function WhySection() {
  return (
    <div id="why-invest-in-a-dag-node" className="why">
      <Container fluid="xl">
        <div className="why__main">
          <h2>Why invest in a $DAG Node?</h2>
          <h3>
            Constellation is blockchain for Big Data. <br />
            True innovation with such a huge impact in the Blockchain space is rare.
          </h3>
          <p>
            You are early. Please inform yourself.<br />
          </p>
          <p>
            Constellation provides the infrastructure that is required to build real-world solutions with the benefits <br/>of blockchain (secure & decentralized), but not the downsides (costly, high energy requirements, limited capacity).
          </p>
          <p>
            It has the potential to disrupt many industries. If this happens, the need for $DAG and $DAG nodes will skyrocket. <br/>
            Dagnode.finance allows everyone to join in early with a lower amount.
          </p>
          <p>
            There have been excellent articles written and explanations recorded on YouTube.<br/>
            If you are new, before you consider to participate in Constellation’s network, we suggest:
          </p>
          <ol>
            <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=CWMayIxyHN8">YouTube - CONSTELLATION - Honest Data for a connected future</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=1b3r-CBs7RY">YouTube - What in the $DAG is Constellation Network?</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=ij5WnmxlBik">YouTube - Will Constellation DAG make you rich?</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://medium.com/@lee.papa/constellation-network-a-scale-free-ecosystem-of-generativity-59608efd8089">Medium - Constellation Network: A Scale-Free Ecosystem of Generativity</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://medium.com/@lee.papa/the-unique-value-proposition-and-bull-case-for-constellation-network-1c260dc03f23">Medium - The Unique Value Proposition and Bull Case for Constellation Network</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://hackernoon.com/dollardag-will-do-to-big-data-what-bitcoin-did-to-money-gj113xoq">Hackernoon - $DAG Will Do To Big Data What Bitcoin Did To Money</a></li>
          </ol>

        </div>
        <div className="why__people-say">
          <Row className="gx-5">
            <Col lg={6}>
              <h3>
                "The most exciting development for the project this year is sure
                to be the launch of Mainnet 2.0. When this happens, the
                floodgates will open and the prices will go nuclear."
              </h3>
              <p><a href="https://medium.com/coinmonks/three-cryptos-with-100x-potential-in-2022-e3b615bc6fb8" target="_blank" rel="noreferrer">Three Cryptos With 100x Potential in 2022</a></p>
              <small>
                Low Risk, High Reward Gems I'm Currently Accumulating
              </small>
            </Col>
            <Col lg={6}>
              <h3>
                “The unique Constellation Blockchain for Big Data technology has significant potential to help Air Force cyber security and the 618 Air Operations Center (AOC) [ ... ] ”
              </h3>
              <p><a href="https://constellationnetwork.io/solutions/federal/" target="_blank" rel="noreferrer">618th Air Communications Squadron Commander Air Mobility Command</a></p>
              <small>
                United States Air Force Letter of Support
              </small>
            </Col>
          </Row>
        </div>
        <Swiper
          className="why__feature"
          slidesPerView={'auto'}
          spaceBetween={25}
          breakpoints={{
            991: { slidesPerView: 3, spaceBetween: 25 },
            1200: { slidesPerView: 3, spaceBetween: 25 },
          }}
        >
          <SwiperSlide className="why__feature__card">
            <img src={dummyFeatureLogoPath} alt="" />
            <h3>Constellation Network</h3>
            <p>
              Constellation is a perfect solution for networks that transfer financial or sensitive data such as personal data or data for IoT devices such as self-driving cars.<br /><br />
            </p>
            <a target="_blank" rel="noreferrer" href="https://constellationnetwork.io/company/about/">
              More about the Constellation Network
            </a>
          </SwiperSlide>
          <SwiperSlide className="why__feature__card">
            <img src={dummyFeatureLogoPath} alt="" />
            <h3>$DAG Token</h3>
            <p>
              DAG is the crypto asset of Constellation.
              Constellation’s nodes are needed for data transfer and verification. They need 250,000 $DAG to operate. So if the use of Constellation grows, so does the need for $DAG tokens.
            </p>
            <a target="_blank" rel="noreferrer" href="https://academy.moralis.io/blog/exploring-constellation-network-and-the-dag-coin">More about the $DAG Token</a>
          </SwiperSlide>
          <SwiperSlide className="why__feature__card">
            <img src={dummyFeatureLogoPath} alt="" />
            <h3>Soft Node Staking</h3>
            <p>
              Soft node staking is a reward program for those who set up a node using 250k $DAG tokens. Node holders receive around 1% interest monthly.<br/><br />
            </p>
            <a target="_blank" rel="noreferrer" href="https://platform.lattice.exchange/softnode-staking/soft-node-program-07">More about Soft Node Staking</a>
          </SwiperSlide>
        </Swiper>
        {/* <div className="why__feature">
          <Row>
            <Col lg={4}>
              <div className="why__feature__card">
              </div>
            </Col>
            <Col lg={4}>
              <div className="why__feature__card">
                
              </div>
            </Col>
            <Col lg={4}>
              <div className="why__feature__card">
                
              </div>
            </Col>
          </Row>
        </div> */}
      </Container>
    </div>
  );
}
