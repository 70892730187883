import React from 'react';
import { Container } from 'react-bootstrap';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import roadmapBadgeBgPath from '../../../assets/roadmap-badge-bg.svg';
import roadmapPatternPath from '../../../assets/roadmap-pattern.svg';
import './roadmap.scss';

export default function Roadmap() {
  return (
    <div id="roadmap" className="roadmap">
      <img
        src={roadmapPatternPath}
        className="roadmap__pattern"
        alt="pattern background"
      />
      <Container fluid="xl">
        <div className="roadmap__heading">
          <h2>Roadmap</h2>
          <h3>Plans for the future</h3>
        </div>

        <Swiper
          modules={[Pagination]}
          slidesPerView={'auto'}
          spaceBetween={40}
          pagination={{ clickable: true }}
          breakpoints={{
            769: { slidesPerView: 2, spaceBetween: 120 },
            991: { slidesPerView: 3, spaceBetween: 120 },
          }}
        >
          <SwiperSlide>
            <h4>STAGE 1</h4>
            <img src={roadmapBadgeBgPath} alt="stage 1 badge" />
            <h3>Project launch</h3>
            <small>June 2022</small>
            <p>
              Build up initial investor base with marketing, optimize dashboards
              and start operations.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h4>STAGE 2</h4>
            <img src={roadmapBadgeBgPath} alt="stage 2 badge" />
            <h3>NFT Minting</h3>
            <small>July and onwards</small>
            <p>
              Joining the $DAG nodes soft staking program.
              Distribution of rewards.
              {/*Bi-weekly AMA's.*/}
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h4>STAGE 3</h4>
            <img src={roadmapBadgeBgPath} alt="stage 3 badge" />
            <h3>Future dev.</h3>
            <small>When Mainnet 2.0 is released</small>
            <p>
              Constellation Network $DAG nodes are operated.
            </p>
          </SwiperSlide>
        </Swiper>
      </Container>
    </div>
  );
}
