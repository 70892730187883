import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import robbertPhotoPath from '../../../assets/robbert-deusing.jpg';
import './investor-security.scss';

export default function InvestorSecurity() {
  return (
    <div id="investor-security" className="investor-security">
      {/*<svg*/}
      {/*  width="398"*/}
      {/*  height="960"*/}
      {/*  viewBox="0 0 398 960"*/}
      {/*  fill="none"*/}
      {/*  xmlns="http://www.w3.org/2000/svg"*/}
      {/*>*/}
      {/*  <path*/}
      {/*    opacity="0.5"*/}
      {/*    d="M831 720.073V240.024L415.5 0L0 240.024V720.073L415.5 960L831 720.073Z"*/}
      {/*    fill="#BCEDFD"*/}
      {/*  />*/}
      {/*</svg>*/}
      <Container fluid="xl">
        <Row>
          {/*<Col*/}
          {/*  lg={{ order: 'last', span: 3 }}*/}
          {/*  className="investor-security__image"*/}
          {/*>*/}
          {/*  <img*/}
          {/*    src={robbertPhotoPath}*/}
          {/*    className="img-fluid"*/}
          {/*    alt="robbert deusing photo"*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col lg={8} className="investor-security__text">
            <h2>Investor Security</h2>
            <h3>
              Our team consists of 2 founders, 4 developers, and a visual
              designer.
            </h3>
            <ul>
              <li>
                Both founders, <a href="https://www.linkedin.com/in/jesse-van-de-zand-a4a08310/" rel="noreferrer" target="_blank">Jesse</a>{" "}
                and <a href="https://www.linkedin.com/in/robbertdeusing" rel="noreferrer" target="_blank">Robbert</a> are publicly doxxed.
              </li>
              <li>Once deployed on mainnet 2.0 our contract will be audited by a third party.</li>
              <li>The wallet addresses will be published on our Docs.</li>
              <li>
                Once available in the Stargazer wallet, we will use a Ledger
                Nano S for each wallet.
              </li>
            </ul>
            <small>
              Please note: We are not associated with Constellation Network. We are a custodial service holding and deploying your tokens for you.
              {/* We cannot give programmatic assurances, as part of our service cannot be governed by smart contracts. */}
            </small>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
