import React, { useEffect, useState } from 'react';
import { Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { numberFormat } from '../../../utils/formatter';
import './input-range.scss';

const defaultOptionFormat = {
  minimumFractionDigits: 0,
};

export default function InputRange({
  label,
  symbol,
  minValue,
  maxValue,
  value,
  setValue,
  step,
  withoutFraction,
  endLabel,
}) {
  const [rangeValue, setRangeValue] = useState(value);
  const [inputValue, setInputValue] = useState(0);

  useEffect(() => {
    setRangeValue(value);
  }, [value]);

  useEffect(() => {
    setValueToInput(rangeValue);
    setValue(Number(rangeValue));
  }, [rangeValue]);

  const handleInputNumberOnBlur = (val) => {
    val = Number(String(val).replaceAll(',', ''));

    if (val < minValue) {
      val = minValue;
    }

    if (val > maxValue) {
      val = maxValue;
    }

    if (val === rangeValue) {
      setValueToInput(val);
    }

    setRangeValue(val);
  };

  const setValueToInput = (val) => {
    const options = {
      maximumFractionDigits: withoutFraction ? 0 : 2,
      minimumFractionDigits: withoutFraction ? 0 : 2,
    };

    const _inputValue = numberFormat(Number(val), 'en-US', options);

    setInputValue(_inputValue);
  };

  return (
    <Form.Group
      className="input-group-xl home-input-range"
      controlId={`form${label.replaceAll(' ', '')}`}
    >
      <Form.Label>{label}</Form.Label>
      <Row>
        <Col xs={10} sm={5} lg={8} xl={7} xxl={6}>
          <InputGroup>
            {symbol?.position === 'start' && (
              <InputGroup.Text id={label.replaceAll(' ', '-')}>
                {symbol.label}
              </InputGroup.Text>
            )}
            <FormControl
              type="text"
              aria-describedby={label.replaceAll(' ', '-')}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={(e) => handleInputNumberOnBlur(e.target.value)}
            />
            {symbol?.position === 'end' && (
              <InputGroup.Text id={label.replaceAll(' ', '-')}>
                {symbol.label}
              </InputGroup.Text>
            )}
          </InputGroup>
        </Col>
        <Col xs={4} sm={7} lg={4} xl={5} xxl={6} className="align-self-center justify-content-between">
          <div className="text-end">{endLabel}</div>
        </Col>
      </Row>
      <Form.Range
        className="m-0"
        min={minValue}
        max={maxValue}
        step={step}
        value={rangeValue}
        onChange={(e) => setRangeValue(Number(e.target.value))}
      />
      <div
        className="d-flex justify-content-between"
        style={{ marginTop: '-7px' }}
      >
        <span className="text-muted">
          {symbol?.position === 'start' && symbol?.label}
          {numberFormat(minValue, 'en-US', defaultOptionFormat)}
          {symbol?.position === 'end' && symbol?.label}
        </span>
        <span className="text-muted">
          {symbol?.position === 'start' && symbol?.label}
          {numberFormat(maxValue, 'en-US', defaultOptionFormat)}
          {symbol?.position === 'end' && symbol?.label}
        </span>
      </div>
    </Form.Group>
  );
}
