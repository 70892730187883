import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InputRange from '../input-range/input-range';
import returnCalculatorPatternPath from '../../../assets/return-calculator-pattern.png';
import dagLogoWithTextPath from '../../../assets/dag-logo-with-text-sm.png';
import Card from '../../common/card/card';
import { numberFormat } from '../../../utils/formatter';
import { DAG_NODE, PLATFORM_FEE } from '../../../constants';
import './return-calculator.scss';

export default function ReturnCalculator() {
  const [amountInvested, setAmountInvested] = useState(5000);
  const [dagPriceAppreciation, setDagPriceAppreciation] = useState(50);
  const [percentApr, setPercentApr] = useState(4);
  const [lockUpPeriod, setLockUpPeriod] = useState(2);
  const [percentRewardLockable] = useState(0.80);
  const [percentRewardLockUpPeriod, setPercentRewardLockUpPeriod] = useState(0);
  const [dagPrice, setDagPrice] = useState(0);
  const [dagAmountIfInvestedNow, setDagAmountIfInvestedNow] = useState(0);
  const [fractionOfDagNode, setFractionOfDagNode] = useState(0);
  const [apr, setApr] = useState(0);
  const [yearlyReturns, setYearlyReturns] = useState(0);
  const [monthlyReturns, setMonthlyReturns] = useState(0);

  useEffect(() => {
    fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=constellation-labs&vs_currencies=usd'
    )
      .then((res) => res.json())
      .then((data) => {
        const usd = data['constellation-labs'].usd;
        setDagPrice(usd);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const _dagAmount = (amountInvested / dagPrice) * (1 / 1.015);
    setDagAmountIfInvestedNow(_dagAmount);
  }, [amountInvested, dagPrice]);

  useEffect(() => {
    const _fractionDagNode = dagAmountIfInvestedNow / DAG_NODE;
    setFractionOfDagNode(_fractionDagNode * 100);
  }, [dagAmountIfInvestedNow]);


  useEffect(() => {
    setPercentRewardLockUpPeriod(lockUpPeriod / 100 + percentRewardLockable);
  }, [lockUpPeriod, percentRewardLockable]);

  useEffect(() => {
    const _yearlyReturns =
      amountInvested *
      dagPriceAppreciation *
      (percentApr / 100) *
      (1 - PLATFORM_FEE);
    setYearlyReturns(_yearlyReturns);
    setMonthlyReturns((_yearlyReturns / 12));
    setApr((_yearlyReturns / amountInvested) * 100);
  }, [amountInvested, dagPriceAppreciation, percentApr, lockUpPeriod]);

  return (
    <div className="return-calculator">
      <Container fluid="xl">
        <img
          src={returnCalculatorPatternPath}
          className="return-calculator__pattern img-fluid"
          alt="background pattern"
        />
        <Card className="return-calculator__card">
          <Card.Header variant="primary">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Return Calculator</h2>
              <img src={dagLogoWithTextPath} alt="dag logo" />
            </div>
          </Card.Header>
          <Row className="return-calculator__input__wrapper">
            <Col lg={4} xl={5}>
              <InputRange
                label="Amount Invested"
                symbol={{ label: 'US$ ', position: 'start' }}
                minValue={dagPrice * 5000 * 1.015}
                maxValue={dagPrice * 250001 * 1.015}
                step={dagPrice * 5000 * 1.015}
                value={amountInvested}
                setValue={(value) => setAmountInvested(value)}
                withoutFraction
              />
              <InputRange
                label="DAG Price Appreciation"
                symbol={{ label: 'x', position: 'end' }}
                minValue={1}
                maxValue={200}
                step={1}
                value={dagPriceAppreciation}
                setValue={(value) => setDagPriceAppreciation(value)}
                withoutFraction
              />
              <InputRange
                label="DAG Node % APR"
                symbol={{ label: '%', position: 'end' }}
                minValue={4}
                maxValue={15}
                step={1}
                value={percentApr}
                setValue={(value) => setPercentApr(value)}
                withoutFraction
              />
            </Col>
            <Col lg={8} xl={7} className="align-self-center mt-5 mt-sm-5 mt-md-0">
              <Card className="bg-white ms-lg-5 return-calculator__card__result">
                <p>Your results after price appreciation:</p>
                <h1>
                  <small>APR</small>{" "}
                  {numberFormat(apr, 'en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  %
                </h1>
                <Row>
                  <Col xs={6}>
                    <p>Monthly Returns</p>
                    <h4 style={{ color: '#37C8FA' }}>
                      US${' '}
                      {numberFormat(monthlyReturns, 'en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </h4>
                  </Col>
                  <Col xs={6}>
                    <p>Yearly Returns</p>
                    <h4 style={{ color: '#37C8FA' }}>
                      US${' '}
                      {numberFormat(yearlyReturns, 'en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </h4>
                  </Col>
                  <Col xs={6}>
                    <p>Amount held on your behalf</p>
                    <h4 style={{ color: '#0000009E' }}>
                      $DAG{' '}
                      {numberFormat(dagAmountIfInvestedNow, 'en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </h4>
                  </Col>
                  <Col xs={6}>
                    <p>Your share of a (soft)<br /> node </p>
                    <h4 style={{ color: '#0000008A' }}>
                      {numberFormat(fractionOfDagNode)} %
                    </h4>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}
