import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import dagLogoWithTextPath from '../../../assets/dag-logo-with-text-sm.png';
import closeIconPath from '../../../assets/close-icon.svg';
import hamburgerIconPath from '../../../assets/hamburger-icon.svg';
import './navbar.scss';

export default function Navbar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isBackgroundDarker, setIsBackgroundDarker] = useState(false);

  const changeBackgroundNavbar = () => {
    if (window === undefined) {
      return;
    }

    if (window.scrollY >= 66) {
      setIsBackgroundDarker(true);
    } else {
      setIsBackgroundDarker(false);
    }
  };

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('scroll', changeBackgroundNavbar);
    }

    return () => {
      if (window !== undefined) {
        window.removeEventListener('scroll', changeBackgroundNavbar);
      }
    };
  }, []);

  return (
    <>
      <div className="navbar__notification-bar">
        <p>We are at capacity, no more deposits are accepted.</p>
      </div>
      <div
        className={`navbar__wrapper ${isBackgroundDarker ? 'navbar__wrapper--darker' : ''
          } ${isCollapsed ? 'navbar__wrapper--collapsed' : ''}`}
      >
        <Container fluid="xl">
          <div className="navbar__content">
            <Link to="/">
              <picture>
                <source media="(min-width: 992px)" srcSet={dagLogoWithTextPath} />

                <img
                  src={dagLogoWithTextPath}
                  className="navbar__content__logo img-fluid"
                  alt="dag node finance logo"
                />

              </picture>
            </Link>
            <button
              className="navbar__toggle"
              onClick={() => setIsCollapsed((value) => !value)}
            >
              <img
                src={isCollapsed ? closeIconPath : hamburgerIconPath}
                alt={isCollapsed ? 'close icon' : 'hamburger icon'}
              />
            </button>
            <ul>
              <li>
                <Link to="#why-invest-in-a-dag-node">Why</Link>
              </li>
              <li>
                <Link to="#how-it-works">How it works</Link>
              </li>
              <li>
                <Link to="#roadmap">Roadmap</Link>
              </li>
              <li>
                <Link to="#investor-security">Investor Security</Link>
              </li>
              <li>
                <a href="https://docs.dagnode.finance/" rel="noreferrer" target="_blank">Docs</a>
              </li>
              <li>
                <Button href="https://app.dagnode.finance/" variant="light" rel="noreferrer" target="_blank">
                  Invest
                </Button>
              </li>
            </ul>
          </div>
          <ul className="navbar__mobile">
            <li>
              <Link to="#why-invest-in-a-dag-node">Why</Link>
            </li>
            <li>
              <Link to="#how-it-works">How it works</Link>
            </li>
            <li>
              <Link to="#roadmap">Roadmap</Link>
            </li>
            <li>
              <Link to="#investor-security">Investor Security</Link>
            </li>
            <li>
              <a href="https://docs.dagnode.finance/" rel="noreferrer" target="_blank">Docs</a>
            </li>
            <li>
              <Button href="https://app.dagnode.finance/" variant="light" rel="noreferrer" target="_blank">
                Invest
              </Button>
            </li>
          </ul>
        </Container>
      </div>
    </>
  );
}
