import React from 'react';
import { Button, Container } from 'react-bootstrap';
import './cta.scss';

export default function Cta({ title, buttonText, buttonLink }) {
  return (
    <div className="cta">
      <Container fluid="xl">
        <h2>{title}</h2>
        <br />
        <Button style={{ width: '300px' }} href={buttonLink} variant="light">
          {buttonText}
        </Button>
      </Container>
    </div>
  );
}
