import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import dagLogoWithTextPath from '../../../assets/dag-logo-with-text-sm.png';
import footerPatternPath from '../../../assets/footer-pattern.svg';
import { numberFormat } from '../../../utils/formatter';
import './footer.scss';

export default function Footer({ dagPriceInUsd }) {
  return (
    <footer>
      <img
        src={footerPatternPath}
        className="footer__pattern"
        alt="background pattern"
      />
      <Container fluid="xl">
        <Row className="gx-lg-5">
          <Col xs={5} lg={7}>
            <Row>
              <Col lg={5}>
                <h2>RESOURCES</h2>
                <a href="https://app.dagnode.finance/" rel="noreferrer" target="_blank">Investor DApp</a>
              </Col>
              <Col lg={5} className="pt-5 pt-lg-0">
                <h2>DOCS</h2>
                <a href="https://docs.dagnode.finance/" rel="noreferrer" target="_blank">GitBook</a>
              </Col>
              <Col className="footer__community--top pt-5">
                <h2>COMMUNITY</h2>
                <a href="https://twitter.com/DagNodeFinance" rel="noreferrer" target="_blank">Twitter</a>
                <a href="https://discord.gg/Wn8NR6g6cn" rel="noreferrer" target="_blank">Discord</a>
              </Col>
            </Row>
          </Col>
          <Col xs={7} lg={4} className="footer__about">
            <img
              src={dagLogoWithTextPath}
              className="footer__about__logo"
              alt="DAG node finance logo"
            />
            <p>
              $DAG Nodes for everyone. A $DAG node currently costs $
              {numberFormat(dagPriceInUsd * 250000, 'en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              . You know the Constellation network will power the future of
              Distributed Ledgers. We pool investors money and soft stake $DAG nodes. Auto
              compound for improved future returns, or take out the rewards.
            </p>
          </Col>
          <Col
            lg={7}
            className="pt-5 pt-lg-0 d-flex flex-column justify-content-end"
          >
            <p>® DAG Node Finance 2022</p>
            <small>
              All calculators and information on this site are not financial
              advice, no rights can be derived
            </small>
          </Col>
          <Col lg={5} className="footer__community--bottom">
            <h2>COMMUNITY</h2>
            <a href="https://twitter.com/DagNodeFinance" rel="noreferrer" target="_blank">Twitter</a>
            <a href="https://discord.gg/Wn8NR6g6cn" rel="noreferrer" target="_blank">Discord</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
