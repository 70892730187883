import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import dagLogoWithTextXlPath from '../../../assets/dag-logo-with-text-xl.png';
import heroPatternPath from '../../../assets/hero-pattern.png';
import dagLogoBG from '../../../assets/dag-logo-bg.png';
import { numberFormat } from '../../../utils/formatter';
import './hero.scss';

function Card({ label, heading }) {
  return (
    <div className="hero__card">
      <svg
        width="58"
        height="52"
        viewBox="0 0 58 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M84.0957 27.5075V-21.4975L42.0957 -46L0.0957031 -21.4975V27.5075L42.0957 52L84.0957 27.5075Z"
          fill="#C4E1FF"
        />
      </svg>
      <p>{label}</p>
      <h4>{heading}</h4>
    </div>
  );
}

export default function Hero({ dagPriceInUsd }) {
  return (
    <div className="hero">
      <img
        src={heroPatternPath}
        className="hero__pattern img-fluid"
        alt="hero pattern"
      />
      {/*<div className="hero__logo d-block d-sm-none">*/}
      {/*    <img src={dagLogoWithTextXlPath} alt="dag node finance logo" />*/}
      {/*</div>*/}
      <Container fluid="xl">
        <div className="hero__normal d-block d-lg-none">
          <img src={dagLogoBG} className="img-fluid" alt="dag node finance logo" />
        </div>
        <div className="hero__logo d-none d-lg-block" >
          <img src={dagLogoWithTextXlPath} alt="dag node finance logo" />
        </div>
        <Row>
          <Col lg={7}>
            <div className="hero__main">
              <h1>$DAG Nodes for everyone</h1>
              <h3>
                A $DAG node currently costs $
                {numberFormat(dagPriceInUsd * 250000, 'en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                . <br />
                You know the Constellation network will power the future of
                Distributed Ledgers.
              </h3>
              <p>
                We pool investors money to soft stake and run $DAG nodes.<br />
                Auto compound for improved future returns, or take out your
                passive income. <br />

              </p>
              <Button href="https://app.dagnode.finance/" rel="noreferrer" target="_blank" variant="light">
                Invest
              </Button>{" "}

              {/* <Button href="https://twitter.com/DagNodeFinance" rel="noreferrer" target="_blank" variant="light">
                Twitter
              </Button>{" "}
              <Button href="https://discord.gg/Wn8NR6g6cn" rel="noreferrer" target="_blank" variant="light">
                Discord
              </Button> */}
            </div>
            <div className="hero__report">
              <Row>
                <Col lg={4}>
                  <Card
                    label="$DAG needed for soft node staking"
                    heading="250,000"
                  />
                </Col>
                <Col lg={4}>
                  <Card
                    label="Current $DAG Price Coingecko"
                    heading={`US$ ${numberFormat(dagPriceInUsd)}`}
                  />
                </Col>
                <Col lg={4}>
                  <Card
                    label="$DAG soft stake node price"
                    heading={`US$ ${numberFormat(
                      dagPriceInUsd * 250000,
                      'en-US',
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }
                    )}`}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
