import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './how-it-works.scss';
import { numberFormat } from "../../../utils/formatter";

export default function HowItWorks({ dagPriceInUsd }) {
  return (
    <div id="how-it-works" className="how-it-works">
      <Container fluid="xl">
        <Row>
          <Col lg={8}>
            <h2>How It Works</h2>
            <h3>
              Co-own a $DAG node through a Dagnode.finance NFT.
            </h3>
            <ol>
              <li>We issue NFTs as proof of ownership, so everyone can co-own a $DAG node. Every NFT is minted with a starting balance, in blocks of 5,000 $DAG. One block currently costs
                US$ {numberFormat(dagPriceInUsd * 5000, 'en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                .</li>
              <li>You can purchase any amount of NFTs on Dagnode.finance using $DAG, $FTM, $USDC/T, $ETH, $AVAX or $BNB.</li>
              <li>99% of your invested amount is used to buy $DAG. We charge a 1% platform fee. If you deposit or withdraw a coin other than $DAG, 0,5% is deducted for costs of trading and transfers. As our process is fully automated and using smart contracts, you will receive your NFT within minutes.</li>
              <li>After deducting node costs, 80% of rewards are distributed proportionally (based on the $DAG Value of your NFT). 20% is used for platform cost, development, management and maintenance.</li>
              <li>You can sell your NFT on the secondary market at any time, or trade in your NFT and take out your total accrued $DAG value.</li>
            </ol>
            <p>During each reward period, investors can select 1 out of 3 options:</p>
          </Col>
        </Row>
        <Row className="gx-5">
          <Col lg={4}>
            <div className="how-it-works__card-option">
              <h4>Auto compound</h4>
              <ul>
                <li>
                  $DAG balance are re-staked.
                </li>
                <li>
                  Rewards are added to the $DAG balance of your NFTs, growing your future returns.
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={4}>
            <div className="how-it-works__card-option">
              <h4>Pay out $DAG rewards</h4>
              <ul>
                <li>$DAG rewards are traded at day rate to $FTM and made claimable.</li>
              </ul>
            </div>
          </Col>
          <Col lg={4}>
            <div className="how-it-works__card-option">
              <h4>Exit our protocol/divest</h4>
              <ul>
                <li>The $DAG balance of your NFT is paid out in $DAG. Or traded at day rate and made claimable in $FTM, or transferred in $ETH, $USDC/T, $AVAX or $BNB.</li>
              </ul>
            </div>
          </Col>
          <small style={{ marginTop: '20px' }}>If the NFT is transferred or burned, the rewards of that period go to the platform.</small>
        </Row>
      </Container>
    </div>
  );
}
