import React, { useEffect, useState } from 'react';
import { GetDagPrice } from '../api/coingecko';
import Layout from '../components/layout';
import ReturnCalculator from '../components/home/return-calculator/return-calculator';
import Hero from '../components/home/hero/hero';
import WhySection from '../components/home/why-section/why-section';
import HowItWorks from '../components/home/how-it-works/how-it-works';
import Cta from '../components/common/cta/cta';
import Roadmap from '../components/home/roadmap/roadmap';
import InvestorSecurity from '../components/home/investor-security/investor-security';

const IndexPage = () => {
  const [dagPriceInUsd, setDagPriceInUsd] = useState(0);

  useEffect(() => {
    handleGetDagPrice();
  }, []);

  const handleGetDagPrice = async () => {
    const usd = await GetDagPrice();
    setDagPriceInUsd(Number(usd));
  };

  return (
    <Layout dagPriceInUsd={dagPriceInUsd}>
      <Hero dagPriceInUsd={dagPriceInUsd} />
      <WhySection />
      <ReturnCalculator />
      <HowItWorks dagPriceInUsd={dagPriceInUsd} />
      <Cta
        title="Still have questions? Need some help?"
        buttonText="Contact us on Discord!"
        buttonLink="https://discord.gg/Wn8NR6g6cn"
      />
      <Roadmap />
      <InvestorSecurity />
    </Layout>
  );
};

export default IndexPage;
