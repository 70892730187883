import React from 'react';
import PropTypes from 'prop-types';
import './card.scss';

const Card = ({ children, className, ...rest }) => (
  <div className={`card__wrapper ${className}`} {...rest}>
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

Card.Header = ({ children, variant = '', className = '' }) => (
  <div className={`card__header card__header--${variant} ${className}`}>
    {children}
  </div>
);

Card.Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
