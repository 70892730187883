import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './common/navbar/navbar';
import Footer from './common/footer/footer';
import '../styles/main.scss';

const Layout = ({ children, dagPriceInUsd }) => (
  <>
    <Navbar />
    <main className="position-relative">{children}</main>
    <Footer dagPriceInUsd={dagPriceInUsd} />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
